import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import s from './registration-button-skin.scss'
import {RsvpButtonStyleProps} from './index'

const getButtonClasses = (secondary: boolean, disabled: boolean, narrowerButton: boolean, studio: boolean) => {
  const classes = [s.registrationButton]
  classes.push(secondary ? (studio ? s.studioSecondary : s.secondary) : s.primary)

  if (disabled) {
    classes.push(s.disabled)
  }

  if (narrowerButton) {
    classes.push(s.narrowerButton)
  }

  return classNames(...classes)
}

export const RegistrationButtonSkin = ({
  onClick,
  url,
  isExternalLink,
  text,
  dataHook,
  secondary,
  disabled,
  minWidth,
  minHeight,
  draftEvent,
  narrowerButton,
}: RsvpButtonStyleProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const buttonClasses = getButtonClasses(secondary, disabled, narrowerButton, !mobileFontOverridesEnabled)
  const style = {minWidth, minHeight}
  let rel = isExternalLink ? 'nofollow' : ''

  if (draftEvent) {
    rel = 'noreferrer'
  }

  return url && !disabled ? (
    <a
      data-hook={dataHook}
      className={classNames(buttonClasses, s.link)}
      href={url}
      style={style}
      rel={rel}
      target={draftEvent ? '_blank' : undefined}
    >
      {text}
    </a>
  ) : (
    <button data-hook={dataHook} className={buttonClasses} onClick={onClick} disabled={disabled} style={style}>
      {text}
    </button>
  )
}
