import {State} from '../../types'

export const isInAccessibilityMode = (state: State) => state.seating.mode.accessibility

export const isInMobileBasket = (state: State) => state.seating.mode.mobileBasket

export const getSelectedPlaceId = (state: State) => state.seating.mode.selectedPlaceId

export const getShowPricingOptionsPlace = (state: State) => state.seating.mode.showPricingOptionsPlace

export const getShowDescriptionPlace = (state: State) => state.seating.mode.descriptionPlace

export const isInDescription = (state: State) => state.seating.mode.showDescription
