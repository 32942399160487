import {getEvent} from '../../../selectors/event'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {FullDate as FullDatePresentation} from './full-date'

export interface FullDateProps extends FullDateRuntimeProps {}

export interface FullDateRuntimeProps {
  event: ExtendedEvent
}

const mapRuntime = ({state}: DetailsPageAppProps): FullDateRuntimeProps => ({
  event: getEvent(state),
})

export const FullDate = connect<{}, FullDateRuntimeProps>(mapRuntime)(FullDatePresentation)
