import React from 'react'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import {Subtitle} from '../../subtitle'
import {SocialShare} from '../index'
import ss from '../social-share.scss'
import s from './with-title.scss'
import {SocialShareWithTitleProps} from './index'

export const SocialShareWithTitle = ({t, page, mobile}: SocialShareWithTitleProps) => {
  const settings = useSettings()
  const shareTitleText = settings.get(settingsParams.shareTitleText)

  return (
    <div className={s.container}>
      <Subtitle className={mobile ? s.subtitle : null} dataHook="social-share" text={shareTitleText} />
      <div className={ss.container}>
        <SocialShare t={t} page={page} />
      </div>
    </div>
  )
}
