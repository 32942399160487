import classNames from 'classnames'
import React from 'react'
import c from '../../classnames.scss'
import s from './custom-dates-label.scss'
import {CustomDatesLabelProps} from './interfaces'

export const CustomDatesLabel = ({text, dataHook}: CustomDatesLabelProps) => (
  <p
    data-hook={dataHook}
    className={classNames(c.evDescriptionFont, c.evDescriptionColor, s.customDatesLabel)}
    role="cell"
    aria-label={text}
  >
    {text}
  </p>
)
