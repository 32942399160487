import React from 'react'

export interface XProps extends React.SVGAttributes<SVGElement> {}

export const X = ({width, height}: XProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 151 142" fill="currentColor">
    <path
      d="M1.24023 0.800049L59.1504 78.2401L0.870117 141.2H13.9902L65.0098 86.08L106.24 141.2H150.88L89.71 59.4101L143.96 0.810049H130.84L83.8496 51.5801L45.8799 0.810049H1.24023V0.800049ZM20.5303 10.46H41.04L131.59 131.53H111.08L20.5303 10.46Z"
      fill="currentColor"
    />
  </svg>
)
