import {INPUT_NAMES} from '@wix/wix-events-commons-statics'
import {CurrentMemberDetails, RegFormData, State} from '../types'

export const getCurrentMemberDetails = (state: State): CurrentMemberDetails => state.currentMemberDetails

export const isMember = (state: State) => !!getCurrentMemberDetails(state).id

export const getCurrentMemberId = (state: State) => state.currentMemberDetails?.id

export const getDefaultFormValues = (state: State): Partial<RegFormData> => {
  if (!isMember(state)) {
    return {}
  }

  const inputValues = state.memberRsvp?.rsvp?.rsvpForm?.inputValues

  return inputValues ? mapInputValues(inputValues) : defaultInputValues(state)
}

const mapInputValues = (inputValues: wix.events.InputValue[]) => {
  const valuesToReturn = {}

  inputValues.forEach(({inputName, values, value}) => {
    const extractedInputName = inputName.split('-')[0]

    if (extractedInputName === INPUT_NAMES.GUEST_NAMES) {
      return
    }

    if (extractedInputName === INPUT_NAMES.ADDITIONAL_GUESTS) {
      valuesToReturn[inputName] = {additionalGuests: value, guestNames: getGuestNames(inputValues)}
    } else {
      valuesToReturn[inputName] = value || (values.length && values) || ''
    }
  })

  return valuesToReturn
}

const defaultInputValues = (state: State) => {
  const {email = '', firstName = '', lastName = ''} = state.currentMemberDetails

  return {
    email,
    firstName,
    lastName,
  }
}

const getGuestNames = (inputValues: wix.events.InputValue[]) =>
  inputValues
    .find(inputValue => inputValue.inputName === INPUT_NAMES.GUEST_NAMES)
    .values.map(name => {
      const splitName = name.split(' ')

      return {
        firstName: splitName[0],
        lastName: splitName[1],
      }
    })
