import React from 'react'

interface ErrorProps {
  className?: string
}

export const Error = ({className}: ErrorProps) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <g className={className} stroke="#DF3131" fill="none" fillRule="evenodd">
      <path d="M12 20.5a8.5 8.5 0 110-17 8.5 8.5 0 010 17zm0 0a8.5 8.5 0 100-17 8.5 8.5 0 000 17zM12 7.02v6.983M12 16.01v.977" />
    </g>
  </svg>
)
