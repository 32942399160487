import {State} from '../types'
import {isSeatingModalOpen} from './modals'

export const getReservationId = (state: State) => state.reservation.data?.id

export const getReservationExpiration = (state: State) => state.reservation.data?.expires

export const getReservationError = (state: State) => state.reservation.error

export const isReservationUnavailable = (state: State) => getReservationError(state) && !isSeatingModalOpen(state)
