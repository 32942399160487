import {getLocale as getLocaleWithDefault} from '@wix/wix-events-commons-statics'
import {IControllerConfig, IHostProps} from '@wix/yoshi-flow-editor'
import {CommonState} from '../types/state'

const MAX_FULL_WIDTH_MARGIN = 106
const MOBILE_MAX_SIZE = 750

export const getLocale = (state: CommonState) => getLocaleWithDefault(state.environment.locale)

export const getLanguage = (state: CommonState) => state.environment.language

export const isPrimaryLanguage = (state: CommonState) => state.multilingual.isPrimaryLanguage

export const isMobile = (state: CommonState) => state.environment.mobile

export const isEditor = (state: CommonState) => state.environment.editor

export const isResponsiveEditor = (config: IControllerConfig) => config?.style?.styleParams?.booleans?.responsive

export const isSite = (state: CommonState) => state.environment.site

export const isPreview = (state: CommonState) => state.environment.preview

export const isHeadless = (state: CommonState) => state.environment.headless

export const hasHeadlessTYP = (state: CommonState) => isHeadless(state) && state.environment.hasHeadlessTYP

export const getHomePageUrl = (state: CommonState) => state.environment.homePageUrl

export const getEventListPageUrl = (state: CommonState) => state.environment.eventListPageUrl

export const getEventListPageRelativeUrl = (state: CommonState) => state.environment.eventListPageRelativeUrl

export const hasHeadlessOriginUrl = (state: CommonState) => isHeadless(state) && getHomePageUrl(state)

export const isFullWidth = (state: CommonState, dimensions: {width: number; left: number}) => {
  if (isMobile(state)) {
    return false
  }

  return (
    String(dimensions.width) === '' || String(dimensions.width) === '100%' || dimensions.left < MAX_FULL_WIDTH_MARGIN
  )
}

export const isResponsive = (host: IHostProps) => host.style.styleParams?.booleans?.responsive

export const isMobileSize = (state: CommonState, host: IHostProps) => {
  if (isResponsive(host)) {
    return host.formFactor === 'Mobile' || isMobile(state)
  }
  return !isFullWidth(state, host.dimensions) && host.dimensions.width < MOBILE_MAX_SIZE
}
