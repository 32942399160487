import React from 'react'

export interface FacebookProps extends React.SVGAttributes<SVGElement> {
  size?: string | number
}

export const Facebook = ({size, ...props}: FacebookProps) => (
  <svg viewBox="0 0 9 16" fill="currentColor" width={size || '9'} height={size || '16'} {...props}>
    <path
      d="M5.39324257,16 L5.39324257,8.70173788 L7.84309113,8.70173788 L8.20960391,5.85761755 L5.39324257,5.85761755 L5.39324257,4.04152086 C5.39324257,3.21816097 5.62190137,2.65686476 6.80283471,2.65686476 L8.30887758,2.65639427 L8.30887758,0.112447437 C8.04822537,0.077631076 7.15429176,0 6.11450584,0 C3.94271768,0 2.45596495,1.32584468 2.45596495,3.75969653 L2.45596495,5.85761755 L-1.0658141e-13,5.85761755 L-1.0658141e-13,8.70173788 L2.45596495,8.70173788 L2.45596495,16 L5.39324257,16 Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
