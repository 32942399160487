import {ISantaProps} from '@wix/native-components-infra/dist/src/types/types'
import React from 'react'
import {RuntimeContext} from './runtime-context'

export function typedConnect<AppProps extends ISantaProps>() {
  return function <OwnProps = {}, StateProps = {}>(
    mapRuntimeToProps: (context: AppProps, props?: OwnProps) => StateProps,
  ) {
    return function (
      WrappedComponent: React.ComponentClass<OwnProps & StateProps> | React.FunctionComponent<OwnProps & StateProps>,
    ): React.ComponentClass<OwnProps> {
      class ConnectedComponent extends React.Component<OwnProps> {
        context: React.ContextType<typeof RuntimeContext>
        static contextType = RuntimeContext

        render() {
          const props = mapRuntimeToProps(this.context as AppProps, this.props)
          return <WrappedComponent {...this.props} {...props} />
        }
      }

      return ConnectedComponent
    }
  }
}

export const connect = typedConnect<ISantaProps>()
