import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {Experiments, useEnvironment, useExperiments} from '@wix/yoshi-flow-editor'
import {useMemo} from 'react'

export const useMobileFontOverrides = () => {
  const {experiments} = useExperiments()
  const {isCssPerBreakpoint} = useEnvironment()

  const studioCompatibilityEnabled = useMemo(() => {
    return isStudioCompatibilityEnabled({experiments, isCssPerBreakpoint})
  }, [])

  return {mobileFontOverridesEnabled: !studioCompatibilityEnabled}
}

export const isStudioCompatibilityEnabled = ({
  experiments,
  isCssPerBreakpoint,
}: {
  experiments: Experiments
  isCssPerBreakpoint: boolean
}) => {
  const studioCompatibilityEnabled = experiments.enabled(ExperimentNames.StudioCompatibility)

  return studioCompatibilityEnabled && isCssPerBreakpoint
}
