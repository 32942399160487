import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import s from './full-location.scss'
import {FullLocationProps} from './index'

export const FullLocation: React.FC<FullLocationProps> = ({event}) => (
  <p data-hook={DH.eventDetailsFullLocation} className={s.locationFont}>
    {getFormattedFullLocation(event)}
  </p>
)
