import React from 'react'
import s from './error-page.scss'

export const ErrorPage = ({viewMode}: {viewMode: string}) => {
  const isSite = viewMode === 'Site'

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    window.location.reload()
  }

  return (
    <div dir="ltr" id="events-error-screen">
      <div className={s.container}>
        <div className={s.box}>
          <h2 className={s.title}>Something went wrong</h2>
          <p className={s.subtitle}>Refresh your page to try again</p>
          {isSite ? (
            <button className={s.button} onClick={handleClick}>
              Refresh Page
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}
