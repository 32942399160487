export interface ColumnConfig {
  content: any
  dataHook?: string
  className?: string
  contentClassName?: string
  dividerClassName?: string
  ariaLabel?: string
  span?: number
  tagName?: string
}

export enum TableRowType {
  normal,
  small,
  large,
  header,
}

export interface RowConfig {
  dataHook?: string
  responsiveHideColumns?: number[]
  itemKey: string
  asText?: boolean
  addDivider?: boolean
  type?: TableRowType
  columns: ColumnConfig[]
  className?: string
  dividerClassName?: string
}

export interface TableRowProps {
  config: RowConfig
  id?: string
  mobile?: boolean
}
