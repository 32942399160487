import {IStyles} from '@wix/tpa-settings'
import {useStyles} from '@wix/tpa-settings/react'
import React, {useMemo} from 'react'

const StylesForAllBreakpointsContext = React.createContext<IStyles[]>(null)

export const StylesForAllBreakpointsProvider: React.FC<{}> = ({children}) => {
  const {getStylesForAllBreakpoints} = useStyles()
  const styles = useMemo(() => getStylesForAllBreakpoints(), [])

  return <StylesForAllBreakpointsContext.Provider value={styles}>{children}</StylesForAllBreakpointsContext.Provider>
}

export const useStylesForAllBreakpoints = () => React.useContext<IStyles[]>(StylesForAllBreakpointsContext)
