import classNames from 'classnames'
import React from 'react'
import {Button, ButtonPriority} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../commons/hooks/use-mobile-font-overrides'
import {classes} from './secondary-button.st.css'
import {SecondaryButtonProps} from './index'

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  onClick,
  className,
  mobile,
  dataHook,
  narrowerButton,
  children,
}) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  return (
    <Button
      upgrade
      priority={ButtonPriority.basicSecondary}
      className={classNames(classes.root, className, mobileFontOverridesEnabled ? classes.font : classes.studioFont, {
        [classes.mobile]: mobile && mobileFontOverridesEnabled,
        [classes.narrowerButton]: narrowerButton,
      })}
      onClick={onClick}
      data-hook={dataHook}
    >
      {children}
    </Button>
  )
}
