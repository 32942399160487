import classNames from 'classnames'
import React from 'react'
import {EventImage} from '../../../../event-image'
import s from './image.scss'
import {ImageProps} from './interfaces'

export const Image: React.FC<ImageProps> = ({imageVisible}) => {
  if (!imageVisible) {
    return null
  }

  return <EventImage className={classNames(s.container)} doubleHeight />
}
