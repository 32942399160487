import {useSlotPlaceholder} from '@wix/widget-plugins-ooi'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {SLOT_EVENT_DETAILS_HEADER} from '../../../../../../../commons/constants/slots'
import alignmentClasses from '../../alignment/alignment.scss'
import {RegistrationButton} from '../../registration-button'
import {Description} from '../description'
import {ShortDateLocation} from '../short-date-location'
import {Title} from '../title'
import {Image} from './image'
import s from './side-by-side.scss'
import {SideBySideLayoutProps} from './index'

export const SideBySideLayout: React.FC<SideBySideLayoutProps> = ({
  onClickRegistrationButton,
  imageVisible,
  fullWidth,
  responsive,
}) => {
  const {experiments} = useExperiments()
  const slotsEnabled = experiments.enabled(ExperimentNames.EventDetailsSlots)
  const [SlotPlaceholder, slotEmpty] = useSlotPlaceholder(SLOT_EVENT_DETAILS_HEADER)

  return (
    <div className={classNames(s.wrapper)}>
      <div className={classNames(s.container, {[s.fullWidth]: fullWidth}, s.containerWithImage)} data-hook={DH.header}>
        {imageVisible ? <Image /> : null}
        <div
          className={classNames(
            s.eventInfoContainer,
            imageVisible ? s.withImage : s.withoutImage,
            fullWidth || responsive ? s.fullWidth : s.minimized,
            s.content,
            alignmentClasses.headerAlignment,
          )}
        >
          <ShortDateLocation />
          <Title />
          {slotsEnabled && !slotEmpty ? (
            <div className={s.slotHeaderContainer}>
              <SlotPlaceholder />
            </div>
          ) : null}
          <Description />
          <RegistrationButton onClick={onClickRegistrationButton} className={s.sideBySideRegistrationButton} />
        </div>
      </div>
    </div>
  )
}
