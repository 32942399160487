import {getEvent} from '../../../selectors/event'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {FullLocation as FullLocationPresentation} from './full-location'

export interface FullLocationProps {
  event: ExtendedEvent
}

const mapRuntime = ({state}: DetailsPageAppProps): FullLocationProps => ({
  event: getEvent(state),
})

export const FullLocation = connect<{}, FullLocationProps>(mapRuntime)(FullLocationPresentation)
