import {
  ISiteTextPreset,
  IWixStyleFont,
  StyleParamType,
  createStylesParam,
  createStylesParams,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings'
import {
  EventDetailsAlignment,
  EventDetailsButtonStyle,
  EventDetailsImageRatio,
  HEADER_IMAGE_ALIGNMENT,
  HEADER_LAYOUT,
  IMAGE_SCALING,
  TICKET_PICKER_LAYOUT,
} from '@wix/wix-events-commons-statics'
import {Override} from '../../commons/types/utils'
import {PageComponentTextSettings} from './settingsParams'
import {getV2FontDefault} from './Widget/utils/css-per-breakpoint'

type IBooleans = {
  shortDateLocationVisible: StyleParamType.Boolean
  descriptionVisible: StyleParamType.Boolean
  membersVisible: StyleParamType.Boolean
  membershipsVisible: StyleParamType.Boolean
  groupVisible: StyleParamType.Boolean
  aboutSectionVisible: StyleParamType.Boolean
  scheduleVisible: StyleParamType.Boolean
  ticketMoreInfoButtonVisible: StyleParamType.Boolean
  mapVisible: StyleParamType.Boolean
  socialShareVisible: StyleParamType.Boolean
  readMoreButtonVisible: StyleParamType.Boolean
  headerRegistrationButtonVisible: StyleParamType.Boolean
  pageRegistrationButtonVisible: StyleParamType.Boolean
  ticketPickerCardsShadowVisible: StyleParamType.Boolean
  responsive: StyleParamType.Boolean
}

type INumbers = {
  headerLayout: StyleParamType.Number
  headerImageAlignment: StyleParamType.Number
  headerBorderWidth: StyleParamType.Number
  evTicketDividerSize: StyleParamType.Number
  rsvpButtonBorderWidth: StyleParamType.Number
  rsvpButtonBorderRadius: StyleParamType.Number
  rsvpButtonRoundedBorderRadius: StyleParamType.Number
  formButtonBorderWidth: StyleParamType.Number
  formButtonBorderRadius: StyleParamType.Number
  formButtonRoundedBorderRadius: StyleParamType.Number
  formSummaryBoxBorderSize: StyleParamType.Number
  imageOpacity: StyleParamType.Number
  buttonStyle: StyleParamType.Number
  imageRatio: StyleParamType.Number
  imageScaling: StyleParamType.Number
  headerAlignment: StyleParamType.Number
  contentAlignment: StyleParamType.Number
  formButtonStyle: StyleParamType.Number
  ticketPickerLayout: StyleParamType.Number
  ticketPickerListSpacing: StyleParamType.Number
  ticketPickerCardSpacing: StyleParamType.Number
  ticketPickerListHorizontalDividerWidth: StyleParamType.Number
  ticketPickerListVerticalDividerWidth: StyleParamType.Number
  ticketPickerListInputBorderRadius: StyleParamType.Number
  ticketPickerCardsInputBorderRadius: StyleParamType.Number
  ticketPickerCardsVerticalDividerWidth: StyleParamType.Number
  ticketPickerCardsBorderWidth: StyleParamType.Number
  ticketPickerCardsBorderRadius: StyleParamType.Number
  ticketPickerCardsShadowAngle: StyleParamType.Number
  ticketPickerCardsShadowDistance: StyleParamType.Number
  ticketPickerCardsShadowBlur: StyleParamType.Number
  mobileShortDateTimeSize: StyleParamType.Number
  mobileEventTitleSize: StyleParamType.Number
  mobileDescriptionSize: StyleParamType.Number
  mobileButtonTextSize: StyleParamType.Number
  mobileSubtitlesSize: StyleParamType.Number
  mobileTextSize: StyleParamType.Number
  mobileScheduleTimeSize: StyleParamType.Number
  mobileScheduleDurationSize: StyleParamType.Number
  mobileScheduleTitleSize: StyleParamType.Number
  mobileScheduleLocationSize: StyleParamType.Number
  mobileTicketsPickerTicketNameSize: StyleParamType.Number
  mobileTicketsPickerMainFontSize: StyleParamType.Number
  mobileTicketsPickerAdditionalFontSize: StyleParamType.Number
}

type IStyles = {
  shortDateLocationFont: StyleParamType.Font
  shortDateLocationColor: StyleParamType.Color
  descriptionFont: StyleParamType.Font
  descriptionColor: StyleParamType.Color
  titleFont: StyleParamType.Font
  titleColor: StyleParamType.Color
  subtitlesFont: StyleParamType.Font
  subtitlesColor: StyleParamType.Color
  textFont: StyleParamType.Font
  textColor: StyleParamType.Color
  evTicketNameFont: StyleParamType.Font
  evTicketNameColor: StyleParamType.Color
  ticketPickerListMainFont: StyleParamType.Font
  ticketPickerCardsMainFont: StyleParamType.Font
  ticketPickerListMainColor: StyleParamType.Color
  ticketPickerCardsMainColor: StyleParamType.Color
  ticketPickerListAdditionalFont: StyleParamType.Font
  ticketPickerCardsAdditionalFont: StyleParamType.Font
  ticketPickerListAdditionalColor: StyleParamType.Color
  ticketPickerCardsAdditionalColor: StyleParamType.Color
  ticketPickerCardsBackgroundColor: StyleParamType.Color
  ticketPickerCardsBorderColor: StyleParamType.Color
  ticketPickerCardsVerticalDividerColor: StyleParamType.Color
  ticketPickerCardsShadowColor: StyleParamType.Color
  ticketPickerListVerticalDividerColor: StyleParamType.Color
  ticketPickerListHorizontalDividerColor: StyleParamType.Color
  scheduleTimeFont: StyleParamType.Font
  scheduleTimeColor: StyleParamType.Color
  scheduleDurationFont: StyleParamType.Font
  scheduleDurationColor: StyleParamType.Color
  scheduleTitleFont: StyleParamType.Font
  scheduleTitleColor: StyleParamType.Color
  scheduleLocationFont: StyleParamType.Font
  scheduleLocationColor: StyleParamType.Color
  headerBackgroundColor: StyleParamType.Color
  evBackgroundColor: StyleParamType.Color
  headerBorderOpacityAndColor: StyleParamType.Color
  colorBehindImage: StyleParamType.Color
  rsvpHollowButtonColor: StyleParamType.Color
  rsvpButtonBorderColor: StyleParamType.Color
  secondRsvpHollowButtonColor: StyleParamType.Color
  secondRsvpButtonBorderColor: StyleParamType.Color
  rsvpHollowButtonFont: StyleParamType.Font
  secondRsvpHollowButtonFont: StyleParamType.Font
  secondRsvpButtonFont: StyleParamType.Font
  rsvpButtonFont: StyleParamType.Font
  rsvpButtonColor: StyleParamType.Color
  rsvpButtonBackgroundColor: StyleParamType.Color
  secondRsvpButtonColor: StyleParamType.Color
  secondRsvpButtonBackgroundColor: StyleParamType.Color
  formSummaryTitleFont: StyleParamType.Font
  formSummaryBodyFont: StyleParamType.Font
  formSummaryBodyColor: StyleParamType.Color
  formSummaryColor: StyleParamType.Color
  formSummaryBoxBackgroundColor: StyleParamType.Color
  formSummaryBoxBorderColor: StyleParamType.Color
  formTitleFont: StyleParamType.Font
  formTitleColor: StyleParamType.Color
  formLabelsFont: StyleParamType.Font
  formLabelsColor: StyleParamType.Color
  formBackgroundColor: StyleParamType.Color
  formHollowButtonFont: StyleParamType.Font
  formHollowButtonColor: StyleParamType.Color
  formButtonBorderColor: StyleParamType.Color
  formButtonBackgroundColor: StyleParamType.Color
  formFullButtonColor: StyleParamType.Color
  formFullButtonFont: StyleParamType.Font
  linksColor: StyleParamType.Color
  headerDateColorV2: StyleParamType.Color
  headerDateFontV2: StyleParamType.Font
  headerLocationFontV2: StyleParamType.Font
  headerLocationColorV2: StyleParamType.Color
  dateColorV2: StyleParamType.Color
  dateFontV2: StyleParamType.Font
  locationColorV2: StyleParamType.Color
  locationFontV2: StyleParamType.Font
  linkFontV2: StyleParamType.Font
  linkColorV2: StyleParamType.Color
  socialShareColorV2: StyleParamType.Color
  textFontV2: StyleParamType.Font
  textColorV2: StyleParamType.Color
  titleFontV2: StyleParamType.Font
  descriptionFontV2: StyleParamType.Font
  rsvpHollowButtonFontV2: StyleParamType.Font
  rsvpButtonFontV2: StyleParamType.Font
  subtitlesFontV2: StyleParamType.Font
  scheduleTimeFontV2: StyleParamType.Font
  scheduleDurationFontV2: StyleParamType.Font
  scheduleTitleFontV2: StyleParamType.Font
  scheduleLocationFontV2: StyleParamType.Font
  ticketPickerListMainFontV2: StyleParamType.Font
  ticketPickerCardsMainFontV2: StyleParamType.Font
  ticketPickerListAdditionalFontV2: StyleParamType.Font
  ticketPickerCardsAdditionalFontV2: StyleParamType.Font
  formTitleFontV2: StyleParamType.Font
  formHollowButtonFontV2: StyleParamType.Font
  formFullButtonFontV2: StyleParamType.Font
}

type IStylesParams = INumbers & IBooleans & IStyles

export type PageComponentSettings = Override<INumbers, number> &
  Override<IBooleans, boolean> & {texts: PageComponentTextSettings}

type ParamsMapper<Type, Val> = {
  [Property in keyof Type]: Val
}

export type DetailsStyleParams = {
  numbers: ParamsMapper<INumbers, number>
  booleans: ParamsMapper<IBooleans, boolean> & {responsive: boolean; __IS_RTL__: boolean}
  colors: IStyles
  fonts: IStyles
  strings: any
}

const getDefaultFont = (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) => {
  return ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }
}

const textColor = createStylesParam('textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
})
const textFont = createStylesParam('textFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const linksColor = createStylesParam('linksColor', {
  type: StyleParamType.Color,
  getDefaultValue: ({getStyleParamValue}) => {
    if (
      [EventDetailsButtonStyle.HOLLOW, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(getStyleParamValue(buttonStyle))
    ) {
      return getStyleParamValue(secondRsvpButtonBorderColor)
    } else {
      return getStyleParamValue(secondRsvpButtonBackgroundColor)
    }
  },
})

const evTicketNameColor = createStylesParam('evTicketNameColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
})
const evTicketNameFont = createStylesParam('evTicketNameFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 20,
  }),
})

const evBackgroundColor = createStylesParam('evBackgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1'),
})

const mobileTicketsPickerTicketNameSize = createStylesParam('mobileTicketsPickerTicketNameSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 20,
})

const secondRsvpButtonBackgroundColor = createStylesParam('secondRsvpButtonBackgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
})

const buttonStyle = createStylesParam('buttonStyle', {
  type: StyleParamType.Number,
  getDefaultValue: () => EventDetailsButtonStyle.FULL,
})

const mobileButtonTextSize = createStylesParam('mobileButtonTextSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 16,
})

const rsvpHollowButtonFont = createStylesParam('rsvpHollowButtonFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const rsvpButtonFont = createStylesParam('rsvpButtonFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const secondRsvpButtonBorderColor = createStylesParam('secondRsvpButtonBorderColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-8'),
})

const formSummaryColor = createStylesParam('formSummaryColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
})

const shortDateLocationFont = createStylesParam('shortDateLocationFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 18,
  }),
})

const mobileShortDateTimeSize = createStylesParam('mobileShortDateTimeSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const mobileTextSize = createStylesParam('mobileTextSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const mobileEventTitleSize = createStylesParam('mobileEventTitleSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 28,
})

const mobileDescriptionSize = createStylesParam('mobileDescriptionSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const mobileSubtitlesSize = createStylesParam('mobileSubtitlesSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 20,
})

const mobileScheduleTimeSize = createStylesParam('mobileScheduleTimeSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const mobileScheduleDurationSize = createStylesParam('mobileScheduleDurationSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const mobileScheduleTitleSize = createStylesParam('mobileScheduleTitleSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const mobileScheduleLocationSize = createStylesParam('mobileScheduleLocationSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const mobileTicketsPickerMainFontSize = createStylesParam('mobileTicketsPickerMainFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(mobileTicketsPickerTicketNameSize) ?? 20,
})

const mobileTicketsPickerAdditionalFontSize = createStylesParam('mobileTicketsPickerAdditionalFontSize', {
  type: StyleParamType.Number,
  getDefaultValue: () => 14,
})

const titleFont = createStylesParam('titleFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Page-title', {
    size: 60,
  }),
})

const descriptionFont = createStylesParam('descriptionFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const rsvpHollowButtonFontV2 = createStylesParam('rsvpHollowButtonFontV2', {
  type: StyleParamType.Font,
  getDefaultValue: params =>
    getV2FontDefault({
      params,
      fontParam: rsvpHollowButtonFont,
      mobileSizeOverrideParam: mobileButtonTextSize,
      defaults: {fontSize: 16, mobileFontSize: 16},
    }),
})

const rsvpButtonFontV2 = createStylesParam('rsvpButtonFontV2', {
  type: StyleParamType.Font,
  getDefaultValue: params =>
    getV2FontDefault({
      params,
      fontParam: rsvpButtonFont,
      mobileSizeOverrideParam: mobileButtonTextSize,
      defaults: {fontSize: 16, mobileFontSize: 16},
    }),
})

const subtitlesFont = createStylesParam('subtitlesFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Page-title', {
    size: 30,
  }),
})

const scheduleTimeFont = createStylesParam('scheduleTimeFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const scheduleDurationFont = createStylesParam('scheduleDurationFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 14,
  }),
})

const scheduleTitleFont = createStylesParam('scheduleTitleFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const scheduleLocationFont = createStylesParam('scheduleLocationFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 14,
  }),
})

const ticketPickerListMainFont = createStylesParam('ticketPickerListMainFont', {
  type: StyleParamType.Font,
  getDefaultValue: ({getStyleParamValue}) => ({
    ...getStyleParamValue(evTicketNameFont),
    size: 20,
  }),
})

const ticketPickerCardsMainFont = createStylesParam('ticketPickerCardsMainFont', {
  type: StyleParamType.Font,
  getDefaultValue: ({getStyleParamValue}) => ({
    ...getStyleParamValue(evTicketNameFont),
    size: 20,
  }),
})

const ticketPickerListAdditionalFont = createStylesParam('ticketPickerListAdditionalFont', {
  type: StyleParamType.Font,
  getDefaultValue: ({getStyleParamValue}) => ({
    ...getStyleParamValue(textFont),
    size: 14,
  }),
})

const ticketPickerCardsAdditionalFont = createStylesParam('ticketPickerCardsAdditionalFont', {
  type: StyleParamType.Font,
  getDefaultValue: ({getStyleParamValue}) => ({
    ...getStyleParamValue(textFont),
    size: 14,
  }),
})

const formTitleFont = createStylesParam('formTitleFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 30,
  }),
})

const formHollowButtonFont = createStylesParam('formHollowButtonFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const formFullButtonFont = createStylesParam('formFullButtonFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultFont('Body-M', {
    size: 16,
  }),
})

const stylesParams = createStylesParams<IStylesParams>({
  mobileButtonTextSize,
  formHollowButtonFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: formHollowButtonFont,
        defaults: {fontSize: 16, mobileFontSize: 16, staticMobileSize: true},
      }),
  },
  formFullButtonFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: formFullButtonFont,
        defaults: {fontSize: 16, mobileFontSize: 16, staticMobileSize: true},
      }),
  },
  formTitleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: formTitleFont,
        defaults: {fontSize: 30, mobileFontSize: 20, staticMobileSize: true},
      }),
  },
  titleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: titleFont,
        mobileSizeOverrideParam: mobileEventTitleSize,
        defaults: {
          preset: 'Page-title',
          fontSize: 60,
          mobileFontSize: 28,
        },
      }),
  },
  descriptionFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: descriptionFont,
        mobileSizeOverrideParam: mobileDescriptionSize,
        defaults: {fontSize: 16, mobileFontSize: 14},
      }),
  },
  subtitlesFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: subtitlesFont,
        mobileSizeOverrideParam: mobileSubtitlesSize,
        defaults: {
          preset: 'Page-title',
          fontSize: 30,
          mobileFontSize: 20,
        },
      }),
  },
  scheduleTimeFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: scheduleTimeFont,
        mobileSizeOverrideParam: mobileScheduleTimeSize,
        defaults: {fontSize: 16, mobileFontSize: 14},
      }),
  },
  scheduleDurationFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: scheduleDurationFont,
        mobileSizeOverrideParam: mobileScheduleDurationSize,
        defaults: {
          fontSize: 14,
          mobileFontSize: 14,
        },
      }),
  },
  scheduleTitleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: scheduleTitleFont,
        mobileSizeOverrideParam: mobileScheduleTitleSize,
        defaults: {fontSize: 16, mobileFontSize: 14},
      }),
  },
  scheduleLocationFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: scheduleLocationFont,
        mobileSizeOverrideParam: mobileScheduleLocationSize,
        defaults: {
          fontSize: 14,
          mobileFontSize: 14,
        },
      }),
  },
  ticketPickerListMainFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: ticketPickerListMainFont,
        mobileSizeOverrideParam: mobileTicketsPickerMainFontSize,
        defaults: {
          fontSize: 20,
          mobileFontSize: 20,
        },
      }),
  },
  ticketPickerCardsMainFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: ticketPickerCardsMainFont,
        mobileSizeOverrideParam: mobileTicketsPickerMainFontSize,
        defaults: {
          fontSize: 20,
          mobileFontSize: 20,
        },
      }),
  },
  ticketPickerListAdditionalFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: ticketPickerListAdditionalFont,
        mobileSizeOverrideParam: mobileTicketsPickerAdditionalFontSize,
        defaults: {
          fontSize: 14,
          mobileFontSize: 14,
        },
      }),
  },
  ticketPickerCardsAdditionalFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: ticketPickerCardsAdditionalFont,
        mobileSizeOverrideParam: mobileTicketsPickerAdditionalFontSize,
        defaults: {
          fontSize: 14,
          mobileFontSize: 14,
        },
      }),
  },
  headerLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => HEADER_LAYOUT.CLASSIC,
  },
  headerImageAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => HEADER_IMAGE_ALIGNMENT.LEFT,
  },
  headerAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => EventDetailsAlignment.CENTER,
  },
  contentAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: ({isRTL}) => (isRTL ? EventDetailsAlignment.RIGHT : EventDetailsAlignment.LEFT),
  },
  buttonStyle,
  imageRatio: {
    type: StyleParamType.Number,
    getDefaultValue: () => EventDetailsImageRatio['16:9'],
  },
  imageScaling: {
    type: StyleParamType.Number,
    getDefaultValue: () => IMAGE_SCALING.CROP,
  },
  headerBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  evTicketDividerSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  rsvpButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  rsvpButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formButtonRoundedBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  formSummaryBoxBorderSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  shortDateLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerDateColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params => {
      const previousValue = params.getStyleParamValue(stylesParams.shortDateLocationColor)
      return previousValue ?? wixColorParam('color-5')(params)
    },
  },
  shortDateLocationFont,
  headerDateFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: shortDateLocationFont,
        mobileSizeOverrideParam: mobileShortDateTimeSize,
        defaults: {
          fontSize: 18,
          mobileFontSize: 14,
        },
      }),
  },
  headerLocationColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: params => {
      const previousValue = params.getStyleParamValue(stylesParams.shortDateLocationColor)
      return previousValue ?? wixColorParam('color-5')(params)
    },
  },
  mobileShortDateTimeSize,
  headerLocationFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: shortDateLocationFont,
        mobileSizeOverrideParam: mobileShortDateTimeSize,
        defaults: {
          fontSize: 18,
          mobileFontSize: 14,
        },
      }),
  },
  dateColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
  mobileTextSize,
  dateFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: textFont,
        mobileSizeOverrideParam: mobileTextSize,
        defaults: {fontSize: 16, mobileFontSize: 14},
      }),
  },
  locationColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
  locationFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: textFont,
        mobileSizeOverrideParam: mobileTextSize,
        defaults: {fontSize: 16, mobileFontSize: 14},
      }),
  },
  shortDateLocationVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  linkColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(linksColor),
  },
  linkFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: textFont,
        defaults: {fontSize: 16, mobileFontSize: 14, staticMobileSize: true, staticDesktopSize: true},
      }),
  },
  textFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getV2FontDefault({
        params,
        fontParam: textFont,
        mobileSizeOverrideParam: mobileTextSize,
        defaults: {fontSize: 16, mobileFontSize: 14},
      }),
  },
  textColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileEventTitleSize,
  titleFont,
  descriptionVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileDescriptionSize,
  descriptionFont,
  subtitlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileSubtitlesSize,
  subtitlesFont,
  textColor,
  textFont,
  evTicketNameColor,
  evTicketNameFont,
  ticketPickerCardsMainColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(evTicketNameColor),
  },
  ticketPickerCardsMainFont,
  ticketPickerListMainColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(evTicketNameColor),
  },
  mobileTicketsPickerTicketNameSize,
  mobileTicketsPickerMainFontSize,
  ticketPickerListMainFont,
  ticketPickerCardsAdditionalColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
  ticketPickerCardsAdditionalFont,
  ticketPickerListAdditionalColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
  mobileTicketsPickerAdditionalFontSize,
  ticketPickerListAdditionalFont,
  ticketPickerCardsBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => {
      const inheritedValue = getStyleParamValue(evBackgroundColor)
      return {
        ...inheritedValue,
        opacity: 1,
      }
    },
  },
  ticketPickerCardsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerCardsVerticalDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerListVerticalDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerListHorizontalDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  ticketPickerCardsShadowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.12),
  },
  scheduleTimeColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileScheduleTimeSize,
  scheduleTimeFont,
  scheduleDurationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  mobileScheduleDurationSize,
  scheduleDurationFont,
  scheduleTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileScheduleTitleSize,
  scheduleTitleFont,
  scheduleLocationColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileScheduleLocationSize,
  scheduleLocationFont,
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  evBackgroundColor,
  headerBorderOpacityAndColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  imageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  colorBehindImage: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-7'),
  },
  rsvpHollowButtonFont,
  rsvpHollowButtonFontV2,
  secondRsvpHollowButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(rsvpHollowButtonFontV2),
  },
  rsvpHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  rsvpButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpButtonBorderColor,
  rsvpButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  rsvpButtonFont,
  rsvpButtonFontV2,
  secondRsvpButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(rsvpButtonFontV2),
  },
  rsvpButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  secondRsvpButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  secondRsvpButtonBackgroundColor,
  membersVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  aboutSectionVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  groupVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  membershipsVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  socialShareVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  mapVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  scheduleVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerRegistrationButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  pageRegistrationButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  ticketPickerCardsShadowVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  readMoreButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  formSummaryColor,
  formSummaryTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 20,
    }),
  },
  formSummaryBodyFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-S', {
      size: 14,
    }),
  },
  formSummaryBodyColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(formSummaryColor),
  },
  formSummaryBoxBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formSummaryBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  formTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formTitleFont,
  formLabelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formLabelsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  formBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formHollowButtonFont,
  formHollowButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formFullButtonFont,
  formFullButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formButtonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(buttonStyle),
  },
  formButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  ticketPickerLayout: {
    type: StyleParamType.Number,
    getDefaultValue: () => TICKET_PICKER_LAYOUT.LIST,
  },
  ticketPickerListSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  ticketPickerCardSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  ticketPickerListVerticalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerCardsVerticalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerListHorizontalDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerCardsBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ticketPickerCardsBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  ticketPickerCardsShadowAngle: {
    type: StyleParamType.Number,
    getDefaultValue: () => 135,
  },
  ticketPickerCardsShadowDistance: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  ticketPickerCardsShadowBlur: {
    type: StyleParamType.Number,
    getDefaultValue: () => 25,
  },
  ticketPickerListInputBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  ticketPickerCardsInputBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  responsive: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  linksColor,
  ticketMoreInfoButtonVisible: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  socialShareColorV2: {
    type: StyleParamType.Color,
    getDefaultValue: ({getStyleParamValue}) => getStyleParamValue(textColor),
  },
})

export default stylesParams

export {customCssVars} from './custom-css-vars'
