import {getFormMessages} from '@wix/wix-events-commons-statics'
import {getEvent} from '../../../selectors/event'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {ClosedRegistrationButton as Presentation} from './closed-registration-button'
import {ClosedRegistrationButtonOwnProps, ClosedRegistrationButtonRuntimeProps} from './interfaces'

export * from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): ClosedRegistrationButtonRuntimeProps => ({
  closedRegistrationMessages: getFormMessages(getEvent(state)).registrationClosed,
})

export const ClosedRegistrationButton = connect<ClosedRegistrationButtonOwnProps, ClosedRegistrationButtonRuntimeProps>(
  mapRuntime,
)(Presentation)
