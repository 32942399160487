import {IStylesGetParam} from '@wix/tpa-settings'
import {useStyles} from '@wix/tpa-settings/react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {useStylesForAllBreakpoints} from './styles-for-all-breakpoints'

const getter = styleParams => param => {
  return (
    styleParams.booleans[param.key] ??
    styleParams.numbers[param.key] ??
    styleParams.colors[param.key] ??
    styleParams.fonts[param.key]
  )
}

export const useVisibilityStylesBuilder = <Selectors extends object>(
  selectors: (getter: IStylesGetParam, mobile?: boolean) => Selectors,
) => {
  const {isEditor, isMobile} = useEnvironment()
  const {get} = useStyles()
  const currentBreakpointSelectors = selectors(get, isMobile)
  const stylesForAllBreakpoints = useStylesForAllBreakpoints().map(styleParams =>
    selectors(getter(styleParams), isMobile),
  )
  const allBreakpointsSelectors = Object.entries(currentBreakpointSelectors)
    .map(([fnName]) => {
      const fn = () => {
        if (isEditor) {
          // In editor components don't get rerendered on style change
          // Assuming SEO is not important in Editor, we can just leave all elements.
          return true
        }
        const styleInAllBps = stylesForAllBreakpoints.map(styleSelector => styleSelector[fnName]())
        return Boolean(styleInAllBps.find(Boolean))
      }
      return {fnName, fn}
    })
    .reduce((obj, {fnName, fn}) => ({...obj, [fnName]: fn}), {} as Selectors)

  return {
    currentBreakpoint: currentBreakpointSelectors,
    allBreakpoints: allBreakpointsSelectors,
  }
}
