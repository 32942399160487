import React from 'react'

export const usePrevious = <Value>(value: Value): Value => {
  const ref = React.useRef<Value>()

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}
