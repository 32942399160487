import {getEvent} from '../../../../selectors/event'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {ShortDateLocationOwnProps, ShortDateLocationRuntimeProps} from './interfaces'
import {ShortDateLocation as Presentation} from './short-date-location'

const mapRuntime = ({state}: DetailsPageAppProps): ShortDateLocationRuntimeProps => ({
  event: getEvent(state),
})

export const ShortDateLocation = connect<ShortDateLocationOwnProps, ShortDateLocationRuntimeProps>(mapRuntime)(
  Presentation,
)
