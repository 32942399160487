import {withSlotsPlaceholders} from '@wix/widget-plugins-ooi'
import {viewerDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars'
import {ErrorPage} from '../../../../../commons/components/error-page'
import {FocusHandler} from '../../../../../commons/components/focus-handler'
import {Direction} from '../../../../../commons/constants/html'
import {CLASSIC_PRESET, CSS_PER_BREAKPOINT_PRESET} from '../../../../../commons/constants/stylePresets'
import {DatesProvider} from '../../../../../commons/hooks/dates'
import {StylesForAllBreakpointsProvider} from '../../../../../commons/hooks/styles-for-all-breakpoints'
import {isMobile, isMobileSize, isPreview, isResponsive} from '../../../../../commons/selectors/environment'
import {ROOT_COMPONENT_ID} from '../../constants'
import {MOBILE_SETTING_POSTFIX} from '../../selectors/settings'
import {Modals} from '../modals'
import {Router} from '../router'
import {DetailsPageRuntimeContext} from '../runtime-context/context'
import s from './app.scss'
import {DetailsPageAppProps} from './interfaces'

class DetailsPage extends React.Component<DetailsPageAppProps> {
  componentDidUpdate(prevProps: DetailsPageAppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
      if (state.navigation.shouldNavigateBack) {
        window.history.back()
      }
    }
  }

  updateStyleParams = styleParams => {
    this.props.actions.updateStyleParams(styleParams)
  }

  updateSettings = action => {
    this.props.actions.updateSettings(action)
  }

  render() {
    const {state, isRTL, host, isCssPerBreakpoint} = this.props

    const stylePreset = isCssPerBreakpoint ? CSS_PER_BREAKPOINT_PRESET : CLASSIC_PRESET

    if (!state) {
      return <ErrorPage viewMode={host.viewMode} />
    }

    const mobile = isMobile(state) || isMobileSize(state, host)
    const responsive = isResponsive(host)

    mergeSettings(this.props, mobile)

    return (
      <DetailsPageRuntimeContext.Provider value={this.props}>
        <TPAComponentsProvider value={{mobile, rtl: isRTL}}>
          <DatesProvider dates={state.dates}>
            <StylesForAllBreakpointsProvider>
              <div
                dir={isRTL ? Direction.RTL : Direction.LTR}
                id={ROOT_COMPONENT_ID}
                className={classNames(s.root, stylePreset)}
                data-hook={DH.ROOT_NODE}
              >
                <Modals />
                <FocusHandler />
                <div
                  className={classNames(s.root, {
                    'events-details-page-mobile': mobile,
                    'events-details-page-desktop': !mobile,
                    'events-details-page-responsive': responsive,
                    'events-details-page-nonresponsive': !responsive,
                  })}
                >
                  <Router />
                </div>
              </div>
            </StylesForAllBreakpointsProvider>
          </DatesProvider>
        </TPAComponentsProvider>
      </DetailsPageRuntimeContext.Provider>
    )
  }
}

const mergeSettings = (props: DetailsPageAppProps, mobile: boolean) => {
  const sources = [props.host.style.styleParams.numbers, props.host.style.styleParams.booleans]

  for (const source of sources) {
    props.state.component.settings = {
      ...props.state.component.settings,
      ...source,
    }
  }

  if (mobile) {
    for (const source of sources) {
      Object.entries(source).forEach(([key, value]) => {
        if (key.endsWith(MOBILE_SETTING_POSTFIX)) {
          props.state.component.settings[key.substring(0, key.length - MOBILE_SETTING_POSTFIX.length)] = value
        }
      })
    }
  }
}

export const App = withSlotsPlaceholders(DetailsPage)
