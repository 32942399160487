import {ScheduleStatus} from '@wix/ambassador-events-schedule-v1-schedule-item/types'
import classNames from 'classnames'
import React from 'react'
import {SectionNotificationSize, SectionNotificationType} from 'wix-ui-tpa/cssVars'
import {useMobileFontOverrides} from '../../../../../../../commons/hooks/use-mobile-font-overrides'
import {Error} from '../../../../../../../icons/Error'
import c from '../../../classnames.scss'
import {SectionNotification} from '../../../styled-components/section-notification'
import {TableRow} from '../../../table-row'
import {Description} from './description'
import s from './list.scss'
import {Time} from './time'
import {UpdatedIndication} from './updated-indication'
import {ListProps} from './index'

export const List = ({items, mobile, t, detailsContentAlignment}: ListProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <ul
      className={classNames(s.list, {
        [s.staticAlignment]: !detailsContentAlignment,
        [s.detailsContentAlignment]: detailsContentAlignment,
      })}
    >
      {items
        .slice(0, 2)
        .map(({formattedTime, formattedDuration, name, stageName, tags, status, formattedUpdatedDate, id}, index) => {
          const canceled = status === ScheduleStatus.CANCELED
          return (
            <li key={id}>
              {canceled ? (
                <SectionNotification
                  size={SectionNotificationSize.compact}
                  type={SectionNotificationType.wired}
                  className={s.cancelNotification}
                  icon={<Error />}
                  text={t('schedule_itemCanceled')}
                />
              ) : null}
              {!canceled && formattedUpdatedDate ? (
                <UpdatedIndication notFirstItem={index > 0} formattedUpdatedDate={formattedUpdatedDate} />
              ) : null}
              <TableRow
                mobile={mobile}
                config={{
                  itemKey: `scheduleItem-${index}`,
                  addDivider: !mobile || index !== 1,
                  className: classNames(mobile ? s.mobileRow : s.desktopRow, {
                    [s.indicationRow]: canceled || formattedUpdatedDate,
                    [s.mobileFontSize]: mobile && mobileFontOverridesEnabled,
                  }),
                  dividerClassName: classNames(s.divider, c.evTicketDividerSize, c.evTicketDividerColor),
                  columns: [
                    {
                      className: s.timeColumn,
                      content: <Time time={formattedTime} duration={formattedDuration} />,
                    },
                    {
                      className: s.descriptionColumn,
                      contentClassName: s.descriptionContent,
                      content: <Description name={name} stageName={stageName} tags={tags} t={t} />,
                    },
                  ],
                }}
              />
            </li>
          )
        })}
    </ul>
  )
}
