import {SettingsParamType, createSettingsParams} from '@wix/tpa-settings'
import type {Override} from '../../commons/types/utils'

type ISettingsParams = {
  rsvpButtonText: SettingsParamType.Text
  rsvpEventButtonText: SettingsParamType.Text
  ticketedEventButtonText: SettingsParamType.Text
  readMoreText: SettingsParamType.Text
  readLessText: SettingsParamType.Text
  timeAndLocationTitleText: SettingsParamType.Text
  guestsTitleText: SettingsParamType.Text
  aboutTitleText: SettingsParamType.Text
  ticketsTitleText: SettingsParamType.Text
  scheduleTitleText: SettingsParamType.Text
  shareTitleText: SettingsParamType.Text
}

export type PageComponentTextSettings = Override<ISettingsParams, string>

export const settingsParams = createSettingsParams<ISettingsParams>({
  rsvpButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: () => undefined,
  },
  rsvpEventButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t, getSettingParamValue}) =>
      getSettingParamValue(settingsParams.rsvpButtonText) || t('listRSVPButtonText'),
  },
  ticketedEventButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t, getSettingParamValue}) =>
      getSettingParamValue(settingsParams.rsvpButtonText) || t('listTicketsButtonText'),
  },
  readMoreText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('readMoreText'),
  },
  readLessText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('readLessText'),
  },
  timeAndLocationTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('timeAndLocationTitleText'),
  },
  guestsTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('guestsTitleText'),
  },
  aboutTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('aboutEventTitleText'),
  },
  ticketsTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('ticketsSectionTitleText'),
  },
  shareTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('shareTitleText'),
  },
  scheduleTitleText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({t}) => t('scheduleTitleText'),
  },
})
