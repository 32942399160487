export const remapFont = (newVarName: string, oldVarName: string) => ({
  [`${newVarName}`]: `var(--${oldVarName})`,
  [`${newVarName}-text-decoration`]: `var(--${oldVarName}-text-decoration)`,
  [`${newVarName}-line-height`]: `var(--${oldVarName}-line-height)`,
  [`${newVarName}-family`]: `var(--${oldVarName}-family)`,
  [`${newVarName}-style`]: `var(--${oldVarName}-style)`,
  [`${newVarName}-size`]: `var(--${oldVarName}-size)`,
  [`${newVarName}-variant`]: `var(--${oldVarName}-variant)`,
  [`${newVarName}-weight`]: `var(--${oldVarName}-weight)`,
})

export const remapColor = (newVarName: string, oldVarName: string) => ({
  [`${newVarName}`]: oldVarName ? `var(--${oldVarName})` : '0, 0, 0, 0',
  [`${newVarName}-rgb`]: oldVarName ? `var(--${oldVarName}-rgb)` : '0, 0, 0',
  [`${newVarName}-opacity`]: oldVarName ? `var(--${oldVarName}-opacity)` : '0',
})
