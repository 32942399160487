import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../commons/hooks/use-mobile-font-overrides'
import cl from '../classnames.scss'
import s from './subtitle.scss'
import {SubtitleProps} from './index'

export const Subtitle = ({dataHook, className, text, mobile, customColor, compact}: SubtitleProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  return (
    <h2
      className={classNames(
        cl.evSubtitlesFont,
        customColor || cl.evSubtitlesColor,
        mobile ? s.mobile : s.desktop,
        compact ? s.compact : '',
        s.upgrade,
        className,
        {[s.mobileFontSize]: mobile && mobileFontOverridesEnabled},
      )}
      data-hook={dataHook}
    >
      {text}
    </h2>
  )
}
