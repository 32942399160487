import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMember} from '../../../selectors/current-member-details'
import {
  allPlansPurchased,
  getNotPurchasedPlans,
  getPaidPlanPromoDiscount,
  getPurchasablePlansWithValidityText,
  hasPurchasedPlans,
} from '../../../selectors/paid-plans'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {MembershipOffersOwnProps, MembershipOffersRuntimeProps} from './interfaces'
import {MembershipOffers as Presentation} from './membership-offers'

const mapRuntime = ({
  state,
  actions: {promptLogin, openMembershipPicker},
}: DetailsPageAppProps): MembershipOffersRuntimeProps => {
  return {
    plans: getPurchasablePlansWithValidityText(state),
    bestOfferDiscountPercent: getPaidPlanPromoDiscount(state),
    hasPurchasedPlans: hasPurchasedPlans(state),
    notPurchasedPlans: getNotPurchasedPlans(state),
    allPlansPurchased: allPlansPurchased(state),
    loggedIn: isMember(state),
    translatedDetails: state.paidPlans.translatedDetails,
    promptLogin,
    openMembershipPicker,
  }
}

export const MembershipOffers = withTranslation()(
  connect<MembershipOffersOwnProps, MembershipOffersRuntimeProps>(mapRuntime)(Presentation),
)
