import {hasImage} from '@wix/wix-events-commons-statics'
import {getEvent} from '../../../../selectors/event'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {ClassicLayout as ClassicLayoutPresentation} from './classic-layout'

export interface ClassicHeaderLayoutProps extends ClassicHeaderLayoutRuntimeProps, ClassicHeaderLayoutOwnProps {}

export interface ClassicHeaderLayoutOwnProps {
  onClickRegistrationButton: Function
}

export interface ClassicHeaderLayoutRuntimeProps {
  imageVisible: boolean
}

const mapRuntime = ({state}: DetailsPageAppProps): ClassicHeaderLayoutRuntimeProps => ({
  imageVisible: hasImage(getEvent(state)),
})

export const ClassicLayout = connect<ClassicHeaderLayoutOwnProps, ClassicHeaderLayoutRuntimeProps>(mapRuntime)(
  ClassicLayoutPresentation,
)
