import {SocialNetworks} from '@wix/panda-js-utils'
import {TFunction} from '@wix/yoshi-flow-editor'

const encodeParams = (params: {[key: string]: string}) =>
  Object.entries(params)
    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
    .join('&')

const getUrl = (platform: SocialNetworks, eventUrl: string, t: TFunction) => {
  const params = encodeParams({
    [platform === SocialNetworks.Facebook ? 'quote' : 'text']: getTitle(platform, t),
  })

  switch (platform) {
    case SocialNetworks.Facebook:
    default:
      return `https://www.facebook.com/sharer/sharer.php?u=${eventUrl}&${params}`
    case SocialNetworks.Twitter:
      return `https://twitter.com/intent/tweet?url=${eventUrl}&${params}`
    case SocialNetworks.LinkedIn:
      // Other params than url does not work anymore with linkedin sharing: https://stackoverflow.com/a/61583006
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(eventUrl)}`
  }
}

const getTitle = (platform: SocialNetworks, t: TFunction) => {
  switch (platform) {
    case SocialNetworks.Facebook:
    default:
      return t('facebookShareTitle')
    case SocialNetworks.LinkedIn:
      return t('linkedInShareTitle')
    case SocialNetworks.Twitter:
      return t('twitterShareTitle')
  }
}

export const getShareUrls = (eventUrl: string, t: TFunction): Record<SocialNetworks, string> => ({
  [SocialNetworks.Facebook]: getUrl(SocialNetworks.Facebook, eventUrl, t),
  [SocialNetworks.LinkedIn]: getUrl(SocialNetworks.LinkedIn, eventUrl, t),
  [SocialNetworks.Twitter]: getUrl(SocialNetworks.Twitter, eventUrl, t),
})
